import h from 'hyperscript';
import errorIcon from '@disy/cadenza-icons/error-small.svg';
import warningIcon from '@disy/cadenza-icons/warning-small.svg';
import infoIcon from '@disy/cadenza-icons/info-small.svg';
import checkIcon from '@disy/cadenza-icons/check.svg';

import type { ModalSize } from 'ui/dialog/modal/modal';
import { Modal } from 'ui/dialog/modal/modal';

import { escapeAndMarkHTML } from 'cadenza/utils/escape-and-mark';
import { icon } from 'cadenza/utils/icon/icon';
import { addStyleClass } from 'cadenza/utils/add-style-class';

import './alert-dialog.css';
import i18n from './alert-dialog.properties';

export type AlertDialogType = 'error' | 'info' | 'warning' | 'success';

interface AlertDialogOptions {
  type?: AlertDialogType;
  submit?: string;
  alternativeSubmit?: string;
  cancel?: boolean | string;
  loadingText?: string;
  alternativeLoadingText?: string;
  submitCallback?: (alertDialog: AlertDialog) => (void | boolean | Promise<unknown>);
  alternativeSubmitCallback?: (alertDialog: AlertDialog) => (boolean | Promise<unknown>);
  size?: ModalSize;
  message?: string | HTMLElement;
  styleClass?: string | string[];
  shouldCloseOnLoadEnd?: boolean;
}

const ALERT_TYPE_TO_ICON: Record<AlertDialogType, string> = {
  error: errorIcon,
  warning: warningIcon,
  info: infoIcon,
  success: checkIcon
};

/**
 * Create modal options rendering a title and title icon for the provided alert type.
 *
 * @param type
 * @return The modal options for the given alert type
 */
export function createAlertModalOptions (type: AlertDialogType) {
  return { title: i18n(`title.${type}`), titleIcon: icon(ALERT_TYPE_TO_ICON[type]) };
}

export class AlertDialog extends Modal<boolean> {

  #submitCallback;
  #alternativeSubmitCallback;
  #loadingText;
  #alternativeLoadingText;
  _shouldCloseOnLoadEnd;

  constructor (
    title: string | Node,
    {
      type,
      submit = i18n('submit.button.ok'),
      alternativeSubmit,
      cancel = false,
      loadingText,
      alternativeLoadingText,
      submitCallback,
      alternativeSubmitCallback,
      size,
      message,
      styleClass,
      shouldCloseOnLoadEnd = true
    }: AlertDialogOptions = {}
  ) {
    super({
      ...(type && createAlertModalOptions(type)),
      size,
      submitButton: submit,
      cancelButton: cancel
    });
    this._shouldCloseOnLoadEnd = shouldCloseOnLoadEnd;
    this.#submitCallback = submitCallback;
    this.#alternativeSubmitCallback = alternativeSubmitCallback;
    this.#loadingText = loadingText;
    this.#alternativeLoadingText = alternativeLoadingText;
    this.dialog.setAttribute('role', 'alertdialog');
    this.dialog.classList.add('alert-dialog');

    if (type) {
      this.dialog.classList.add(`alert-dialog-${type}`);
    }

    addStyleClass(this, styleClass);
    if (typeof title === 'string') {
      this.body.append(h('h2.alert-dialog--title', { innerHTML: escapeAndMarkHTML(title, false) }));
    } else {
      this.body.append(h('h2.alert-dialog--title', title));
    }
    if (message) {
      this.body.append(typeof message === 'string'
        ? h('p', { innerHTML: escapeAndMarkHTML(message, false) })
        : message
      );
    }

    if (alternativeSubmit) {
      this.footer.append(h('button.button', { type: 'button', onclick: () => this._onAlternativeSubmit() }, alternativeSubmit));
    }
  }

  onLoadingEnd () {
    if (this._shouldCloseOnLoadEnd) {
      this.close(true);
    }
  }

  onSubmit () {
    this._onSubmitInternal(this.#submitCallback, this.#loadingText);
  }

  _onAlternativeSubmit () {
    this._onSubmitInternal(this.#alternativeSubmitCallback, this.#alternativeLoadingText);
  }

  _onSubmitInternal (callback?: (alertDialog: AlertDialog) => (void | boolean | Promise<unknown>), loadingText?: string) {
    if (callback) {
      const result = callback(this);
      if (result instanceof Promise) {
        this.setLoading(result, loadingText);
        return;
      }
    }
    this.close(true);
  }

}

customElements.define('alert-dialog', AlertDialog);
